import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../../01_atoms/Link';

const FooterBigMenu = ({ menu = [] }) => (
  <div className="footer-big-menu">
    {menu.map((item) => (
      <div className="footer-big-submenu" key={item.label}>
        <h5 className="footer-big-submenu__label">{item.label}</h5>
        <ul className="icon-list">
          {item.links.map((contentItem) => (
            <li key={contentItem.text}>
              <Link {...contentItem.nextLink}>
                <a className="footer-big-menu--link">{contentItem.text}</a>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    ))}
  </div>
);

FooterBigMenu.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      content: PropTypes.shape({
        text: PropTypes.string,
        nextLink: PropTypes.shape({
          href: PropTypes.string,
          as: PropTypes.string,
        }),
      }),
    }),
  ),
};

export default FooterBigMenu;
